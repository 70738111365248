<!--  -->
<template>
  <div class="" style="height:100%">
    <vue-better-scroll
      class="wrapper"
      ref="scroll"
      :scrollbar="scrollbarObj"
      :pullDownRefresh="pullDownRefreshObj"
      :pullUpLoad="pullUpLoadObj"
      :startY="parseInt(startY)"
      @pulling-down="onPullingDown"
      @pulling-up="onPullingUp"
    >
      <div class="searchComponent">
        <van-tabs v-model="active" @click="onTabClick">
          <van-tab title="工单状态" name="1">
            <div class="qeuryTypesWrapper">
              <van-radio-group
                v-model="query.status"
                class="myStyledRadio"
                @change="statusChange"
              >
                <van-radio
                  :name="item.value"
                  v-for="item in meetingStatus"
                  :key="item.value"
                  >{{ item.name }}</van-radio
                >
              </van-radio-group>
            </div>
          </van-tab>

          <van-tab title="数据筛选" name="5" v-if="!isAdmin">
            <div class="qeuryTypesWrapper" v-show="tabVisible">
              <van-radio-group
                v-model="query.self"
                class="myStyledRadio"
                @change="dataTypeChange"
              >
                <van-radio
                  :name="item.value"
                  v-for="item in dataTypeSelections"
                  :key="item.value"
                  >{{ item.name }}</van-radio
                >
              </van-radio-group>
            </div>
          </van-tab>

          <van-tab title="会议等级" name="2">
            <van-radio-group
              v-show="tabVisible"
              v-model="query.level"
              class="myStyledRadio"
              @change="levelChange"
            >
              <van-radio
                :name="item.value"
                v-for="item in meetingLevels"
                :key="item.value"
                >{{ item.name }}</van-radio
              >
            </van-radio-group>
          </van-tab>
          <van-tab title="时间筛选" name="3" v-if="isAdmin">
            <div class="qeuryTypesWrapper" v-show="tabVisible">
              <van-radio-group
                v-model="query.dayNums"
                class="myStyledRadio"
                @change="dayNumsChange"
              >
                <van-radio
                  :name="item.value"
                  v-for="item in daySelections"
                  :key="item.value"
                  >{{ item.name }}</van-radio
                >
              </van-radio-group>
            </div>
          </van-tab>
        </van-tabs>
      </div>

      <div class="searchInputComponent">
        <div class="flexWrapper">
          <van-search
            v-model="query.queryParam"
            show-action
            placeholder="请输入搜索关键词"
            @search="onSearch"
          >
            <template #action>
              <van-icon
                name="sort"
                @click="handleOrderClick"
                size="1.3rem"
                color="#2BB7B3"
              >
              </van-icon>
            </template>
          </van-search>
        </div>
      </div>

      <div style="margin:0 10px;border-radius:5px" v-if="addMeetingAuth">
        <van-button
          icon="plus"
          type="primary"
          style="background-color:#2bb7b3;width:100%"
          @click="addMeeting"
          >新增工单</van-button
        >
      </div>

      <div class="meetingList">
        <div
          class="singleWrapper"
          v-for="item in list"
          :key="item.workorderId"
          @click="viewDetails(item)"
        >
          <div class="meetingTheme">
            <div
              class="van-ellipsis meetingTitle"
              style="max-width:70%;text-align:left"
            >
              {{ item.theme }}
            </div>
            <span class="status">{{ item.status | meetingStatus }}</span>
          </div>
          <div class="roomName">
            {{ item.roomName }}
          </div>
          <div class="meetingTime">
            <div class="time">
              <span>{{
                item.startTime ? item.startTime.slice(0, 16) : ""
              }}</span>
              <span>~</span>
              <span>{{ item.endTime ? item.endTime.slice(0, 16) : "" }}</span>
            </div>
            <div class="icon">
              <div :class="['round', colorChecktor(item.level)]"></div>
              <div :class="['level', textColorChecktor(item.level)]">
                {{ item.level | levelStatus }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </vue-better-scroll>
    <!-- <button class="go-top"
            @click="scrollTo">返回顶部</button> -->
    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        type="datetime"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getMeetingList } from "@/api/meetingList";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      active: "1",
      scrollbarObj: {
        fade: true,
      },
      pullDownRefreshObj: {
        threshold: 60,
        stop: 40,
      },
      pullUpLoadObj: {
        threshold: 20,
        txt: {
          more: "加载更多",
          noMore: "没有更多数据了",
        },
      },
      startY: 0, // 纵轴方向初始化位置
      scrollToX: 0,
      scrollToY: 0,
      scrollToTime: 700,
      query: {
        pageSize: 10,
        currentPage: 1,
        startTime: undefined,
        endTime: undefined,
        queryParam: "",
        level: "",
        status: "",
        rank: true,
        dayNums: 7,
        self: true,
      },
      queryStartTime: "",
      queryEndTime: "",
      meetingStatus: [
        { name: "全部", value: "" },
        { name: "未派单", value: 0 },
        { name: "待开始", value: 1 },
        { name: "进行中", value: 2 },
        { name: "已完成", value: 3 },
        { name: "已取消", value: 4 },
      ],
      meetingLevels: [
        { name: "全部", value: "" },
        { name: "VIP会议", value: 1 },
        { name: "普通会议", value: 2 },
        { name: "重要会议", value: 3 },
        { name: "会议支持", value: 4 },
      ],
      daySelections: [
        { name: "全部", value: "" },
        { name: "今日", value: 1 },
        { name: "近三天", value: 3 },
        { name: "近一周", value: 7 },
      ],
      dataTypeSelections: [
        { name: "只看自己", value: true },
        { name: "全部人员", value: false },
      ],
      showPicker: false,
      list: [],
      isRequiring: false,
      tabVisible: false,
    };
  },
  //监听属性 类似于data概念
  computed: {
    currentRoleId() {
      return this.$store.state.user.roleId;
    },
    userAuthority() {
      return this.$store.state.user.authority
        ? JSON.parse(this.$store.state.user.authority)
        : [];
    },
    meetingAuth() {
      let meetingAuthObj = this.userAuthority.filter((item) => {
        return item.permissionName === "会议工单";
      })[0];
      return meetingAuthObj;
    },
    addMeetingAuth() {
      let addMeetingAuthObj = this.meetingAuth.subPermission.filter((item) => {
        return item.permissionName === "新建工单";
      })[0];
      return addMeetingAuthObj ? addMeetingAuthObj.permissionType : false;
    },
    isAdmin() {
      return this.$store.state.user.IsAdmin;
    },
  },
  //监控data中的数据变化
  watch: {},
  filters: {
    meetingStatus(status) {
      if (status == null || status == undefined) return "";
      let statusObj = {
        0: "未派单",
        1: "待开始",
        2: "进行中",
        3: "已完成",
        4: "已取消",
      };
      return `【 ${statusObj[status]} 】`;
    },
    levelStatus(level) {
      if (level == null || level == undefined) return "";
      let levelObj = {
        1: "VIP会议",
        2: "普通会议",
        3: "重要会议",
        4: "会议支持",
      };
      return `${levelObj[level]}`;
    },
  },
  //方法集合
  methods: {
    onTabClick() {
      this.tabVisible = true;
    },
    searchStatusInit() {
      this.meetingStatus = this.isAdmin
        ? [
            { name: "全部", value: "" },
            { name: "未派单", value: 0 },
            { name: "待开始", value: 1 },
            { name: "进行中", value: 2 },
            { name: "已完成", value: 3 },
            { name: "已取消", value: 4 },
          ]
        : [
            { name: "全部", value: "" },
            { name: "待开始", value: 1 },
            { name: "进行中", value: 2 },
            { name: "已完成", value: 3 },
            { name: "已取消", value: 4 },
          ];
    },
    addMeeting() {
      this.$store.commit("SetNavBarTitle", "新建工单");
      this.$store.commit("SetIsEditing", false);
      this.$router.push("/addMeeting");
    },
    viewDetails(item) {
      this.$store.commit("SetCurrentMeeting", item);
      this.$store.commit("SetNavBarTitle", "工单信息");
      this.$router.push("/meetingDetails");
    },
    textColorChecktor(level) {
      if (level == null || level == undefined) return "";
      let levelObj = {
        1: "vieMeetingText",
        2: "ordinaryMeetingText",
        3: "importantMeetingText",
        4: "supportingMeetingText",
      };
      return `${levelObj[level]}`;
    },
    colorChecktor(level) {
      if (level == null || level == undefined) return "";
      let levelObj = {
        1: "vieMeetingRound",
        2: "ordinaryMeetingRound",
        3: "importantMeetingRound",
        4: "supportingMeetingRound",
      };
      return `${levelObj[level]}`;
    },
    handleOrderClick() {
      this.query.rank = !this.query.rank;
      this.onSearch();
    },
    onSearch() {
      console.log(this.query, "search");
      this.query.currentPage = 1;
      this.query.dayNums = "";
      this.query.self = false;
      this.search();
    },
    search() {
      getMeetingList(this.query).then((res) => {
        if (res.code === 10000) {
          this.list = res.data.records;
        } else {
          this.$notify({ type: "warning", message: res.message });
        }
      });
    },
    getMoreMeetings() {
      getMeetingList(this.query).then((res) => {
        this.isRequiring = false;
        if (res.code === 10000) {
          this.list = this.list.concat(res.data.records);
          if (res.data.records.length < this.query.pageSize) {
            this.$refs.scroll.forceUpdate(false);
          } else {
            this.$refs.scroll.forceUpdate(true);
          }
        } else {
          this.$notify({ type: "warning", message: res.message });
        }
      });
    },
    openTimePicker(order) {
      this.timeOrder = order;
      this.showPicker = true;
    },
    onConfirm(time) {
      if (this.timeOrder === 1) {
        this.query.startTime = time;
        this.queryStartTime = this.$moment(time).format("YYYY-M-D H:m");
      } else {
        this.query.endTime = time;
        this.queryEndTime = this.$moment(time).format("YYYY-M-D H:m");
      }
      this.showPicker = false;
    },
    levelChange() {
      this.query.currentPage = 1;
      this.search();
    },
    dayNumsChange() {
      this.query.currentPage = 1;
      this.search();
    },
    dataTypeChange() {
      this.query.currentPage = 1;
      this.search();
    },
    statusChange() {
      this.query.currentPage = 1;
      this.search();
    },
    // 滚动到页面顶部
    scrollTo() {
      this.$refs.scroll.scrollTo(
        this.scrollToX,
        this.scrollToY,
        this.scrollToTime
      );
    },
    onPullingDown() {
      // 模拟下拉刷新
      console.log("下拉刷新");
      this.query.currentPage = 1;

      getMeetingList(this.query).then((res) => {
        if (res.code === 10000) {
          this.list = res.data.records;
          // if (this.list.length < 30) {
          //   this.$refs.scroll.forceUpdate(true);
          // } else {
          this.$refs.scroll.forceUpdate(true);
          // }
        } else {
          this.$notify({ type: "warning", message: res.message });
        }
      });
    },
    onPullingUp() {
      // 模拟上拉 加载更多数据
      console.log("上拉加载");
      if (this.isRequiring) {
        return;
      }
      this.query.currentPage++;
      this.isRequiring = true;
      this.getMoreMeetings();
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.searchStatusInit();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    if (this.isAdmin + "" === "true") {
      this.query.dayNums = 1;
      this.query.self = false;
    } else {
      this.query.dayNums = 3;
      this.query.self = true;
    }
    this.search();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("SetNavBarTitle", to.meta.name);
    next();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style>
/* @import url(); 引入公共css类*/
.searchComponent {
  background-color: #fff;
  margin: 10px;
  margin-top: 50px;
  border-radius: 5px;
}
.searchComponent .van-tabs__wrap {
  margin-bottom: 0.5rem;
}
.searchComponent .van-tabs__line {
  background-color: #2bb7b3;
}
.myStyledRadio {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.myStyledRadio .van-radio__icon {
  display: none;
}
.myStyledRadio .van-radio__label {
  font-size: 0.4rem;
  display: inline-block;
  border: 1px solid black;
  border-radius: 3px;
  width: 3rem;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  margin-left: 1.3rem;
  text-align: center;
}
.myStyledRadio .van-radio__icon--checked + .van-radio__label {
  color: #2bb7b3;
  background-color: #e9f8f7;
}
.searchInputComponent {
  margin: 10px;
}
.singleWrapper {
  background-color: #fff;
  border-radius: 5px;
  margin: 10px;
}
.meetingTheme {
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding: 8px;
}
.meetingTheme .meetingTitle {
  font-size: 0.7rem;
}
.meetingTheme .status {
  font-size: 0.7rem;
  color: #2bb7b3;
  margin-left: 0.6rem;
}
.roomName {
  font-size: 0.3rem;
  color: #8e8e8e;
  text-align: left;
  padding-left: 8px;
}
.icon {
  display: flex;
  align-items: center;
}
.round {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
}
.level {
  font-size: 0.5rem;
  margin-left: 0.3rem;
}
.meetingTime {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}
.meetingTime .time {
  font-size: 0.3rem;
  color: #8e8e8e;
}
.vieMeetingRound {
  background-color: #ed6c00;
}
.ordinaryMeetingRound {
  background-color: #2bb7b3;
}
.importantMeetingRound {
  background-color: #dc1414;
}
.supportingMeetingRound {
  background-color: #00bfff;
}

.vieMeetingText {
  color: #ed6c00;
}
.ordinaryMeetingText {
  color: #2bb7b3;
}
.importantMeetingText {
  color: #dc1414;
}
.supportingMeetingText {
  color: #00bfff;
}
</style>
